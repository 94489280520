<template>
<div class="text-center mb-5 mt-lg-4">
      <h3>{{title}}</h3>
</div>
</template>

<script>
export default {
      name: 'Title',
      props: ["title"]
};
</script>
